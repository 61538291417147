import { transformStaticFilterToPropertySearchFilter } from '@dreamstack/accentro-contentful'
import type { PropertySearchFilterValues } from '@dreamstack/feature-components'
import {
  applyPropertiesFilter,
  fetchTopCities,
  getCityUrl,
  getPropertyOverviewRedirectUrl,
  MainLayout,
  PropertyFilterOverview,
  propertyPriceVisibility,
  UNKNOWN_CITY_URL_PART,
  usePropertyOverviewUrl,
} from '@dreamstack/feature-components'
import type { IOtherLocaleUrlsContext, LocaleString } from '@dreamstack/i18n'
import {
  OtherLocaleUrlsContextProvider,
  useTranslation,
} from '@dreamstack/i18n'
import type { StaticFilterFragment } from '@dreamstack/investors-graphql'
import { queryGetAllStaticFilters } from '@dreamstack/investors-graphql'
import { DreamstackSeo } from '@dreamstack/seo'
import type { ApolloClientDreamstack } from '@dreamstack/web-graphql'
import filter from 'lodash/filter'
import find from 'lodash/find'
import includes from 'lodash/includes'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import type { FunctionComponent } from 'react'
import { defaultQueries } from '../../../lib/defaultQueries'
import { getStaticPathsPlus } from '../../../lib/getStaticPathsPlus'
import { getStaticPropsPlus } from '../../../lib/getStaticPropsPlus'
import { getAllProperties } from '../../../lib/prisma/properties'

const CityOverview: FunctionComponent<
  React.PropsWithChildren<{
    filter?: StaticFilterWithSlugAndSeoText
    noIndex?: boolean
  }>
> = ({ filter, noIndex }) => {
  const propertyOverviewUrl = usePropertyOverviewUrl()
  const otherLocaleUrls: IOtherLocaleUrlsContext = {
    de: { url: getCityUrl({ locale: 'de', city: filter?.city }) },
    en: { url: getCityUrl({ locale: 'en', city: filter?.city }) },
  }
  const t = useTranslation()
  return (
    <>
      <OtherLocaleUrlsContextProvider context={otherLocaleUrls}>
        <DreamstackSeo
          title={filter?.seo?.title ?? t('accentro:propertyOverview.title')}
          description={filter?.seo?.description ?? ''}
          nofollow={false}
          noindex={noIndex}
        />
        <MainLayout navbarProps={{ forceDarkTextColor: true }}>
          <PropertyFilterOverview
            staticFilter={filter}
            seoText={filter?.seoText ?? undefined}
            activatePropertySearchQueryUrlSync={true}
            forcePropertySearchQueryUrlSyncUrl={propertyOverviewUrl}
          />
        </MainLayout>
      </OtherLocaleUrlsContextProvider>
    </>
  )
}

export default CityOverview

export const getStaticProps = getStaticPropsPlus({
  queries: [...defaultQueries],

  getProps: async (ctx) => {
    const validProperties = await getAllProperties({
      locale: (ctx.locale as LocaleString) ?? ('de' as LocaleString),
    })

    const { apolloClient, params, locale } = ctx
    const slugPathParam = (params as Params).slug

    // Redirect if UNKNOWN_CITY_URL_PART
    if (locale && includes(UNKNOWN_CITY_URL_PART, slugPathParam)) {
      const destination = getPropertyOverviewRedirectUrl({
        locale: locale as LocaleString,
      })
      return {
        redirect: {
          destination,
        },
      }
    }

    const allStaticFilters = await fetchAllStaticFiltersWithSlugs({
      apolloClient,
    })

    const matchingFilter = find(
      allStaticFilters,
      ({ slug }) => slug === slugPathParam
    )
    if (!matchingFilter) {
      return { notFound: true }
    }

    const mappedFilter: PropertySearchFilterValues =
      transformStaticFilterToPropertySearchFilter({
        staticFilter: matchingFilter,
      })
    const filteredProperties = applyPropertiesFilter({
      filter: mappedFilter,
      validProperties,
      isReservationUi: false,
      propertyPriceVisibility,
    })
    const noIndex = filteredProperties.length < 3

    return { props: { filter: matchingFilter, noIndex } }
  },
})

export const getStaticPaths = getStaticPathsPlus({
  getPaths: async ({ apolloClient }) => {
    // if (SKIP_STATIC_PAGE_GENERATION) {
    //   return []
    // }
    let allStaticFilters = await fetchAllStaticFiltersWithSlugs({
      apolloClient,
    })
    // Empty urlPart breaks everything because it will overlap wth /properties
    allStaticFilters = filter(allStaticFilters, ({ slug }) => !!slug)

    return map<StaticFilterWithSlugAndSeoText, { params: Params }>(
      allStaticFilters,
      (filter) => ({
        params: { slug: filter.slug ?? '' }, // Slug will alway be defined here, since we filtered out all filters without slug above.
      })
    )
  },
})

type Params = {
  slug: string
}

type StaticFilterWithSlugAndSeoText = StaticFilterFragment

/**
 * Get's all regions from contentful that have an entry in content type
 * "Region Description Text", plus those that are declared "top cities"
 * and are displayed at the bottom of the homepage.
 */
export const fetchAllStaticFiltersWithSlugs = async ({
  apolloClient,
}: {
  apolloClient: ApolloClientDreamstack
}): Promise<StaticFilterWithSlugAndSeoText[]> => {
  const { data } = await queryGetAllStaticFilters({
    apolloClient,
  })

  // Regions from contentful for Seo texts.
  const staticFiltersFromContentful: StaticFilterWithSlugAndSeoText[] =
    data.staticFilters?.items
      .filter((filter) => !!filter?.slug)
      .map((filter) => ({
        ...filter,
        slug: filter?.slug!,
      })) ?? []

  // Top cities from static file for footer.
  const topCities = await fetchTopCities()
  const topCityFilters = map(topCities, (e) => {
    const filter: StaticFilterWithSlugAndSeoText = {
      city: e.name,
      slug: e.urlPart,
      seoText: null,
    }
    return filter
  })

  const allUrlParts = uniqBy(
    [...staticFiltersFromContentful, ...topCityFilters],
    (url) => url.slug
  )

  return allUrlParts
}
