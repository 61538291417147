export type CityOrLocation = {
  region?: string
  name: string
}

export const TOP_CITIES: CityOrLocation[] = [
  {
    region: 'Berlin',
    name: 'Berlin',
  },
  {
    region: 'Brandenburg',
    name: 'Schöneiche',
  },
  {
    region: 'Brandenburg',
    name: 'Potsdam',
  },
  {
    region: 'Mecklenburg-Vorpommern',
    name: 'Rostock',
  },
  {
    region: 'Mecklenburg-Vorpommern',
    name: 'Usedom',
  },
  {
    region: 'Nordrhein-Westfalen',
    name: 'Bedburg',
  },
  {
    region: 'Nordrhein-Westfalen',
    name: 'Düsseldorf',
  },
  {
    region: 'Nordrhein-Westfalen',
    name: 'Köln',
  },
  {
    region: 'Nordrhein-Westfalen',
    name: 'Ratingen',
  },
  {
    region: 'Nordrhein-Westfalen',
    name: 'Rheinberg',
  },
  {
    region: 'Sachsen',
    name: 'Leipzig',
  },
  {
    region: 'Sachsen',
    name: 'Böhlen',
  },
  {
    region: 'Sachsen',
    name: 'Oschatz',
  },
  {
    region: 'Sachsen',
    name: 'Zwenkau',
  },
  {
    region: 'Schleswig-Holstein',
    name: 'Ahrensburg',
  },
]
